<template>
  <ButtonV3 v-bind="propMapping">
    <slot />
  </ButtonV3>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import ButtonV3 from './ButtonV3.vue';

const props = defineProps({
  color: {
    type: String,
    default: 'primary',
  },
  type: {
    type: String,
    default: 'primary',
  },
  iconSize: {
    type: Number,
    default: 14,
  },
  large: {
    type: Boolean,
  },
  small: {
    type: Boolean,
  },
  disabled: {
    type: Boolean,
  },
  iconLeft: {
    type: String,
    default: null,
  },
  iconRight: {
    type: String,
    default: null,
  },
  loading: {
    type: Boolean,
  },
  success: {
    type: Boolean,
  },
});

const COLOR_TYPE_MAPPING = {
  primary_primary: { type: 'primary', tone: 'neutral' },
  primary_blue: { type: 'primary', tone: 'neutral' },
  primary_red: { type: 'primary', tone: 'destructive' },
  primary_green: { type: 'primary', tone: 'neutral' },
  primary_grey: { type: 'primary', tone: 'neutral' },
  primary_orange: { type: 'primary', tone: 'neutral' },
  primary_black: { type: 'primary', tone: 'neutral' },
  secondary_primary: { type: 'secondary', tone: 'neutral' },
  secondary_blue: { type: 'secondary', tone: 'neutral' },
  secondary_red: { type: 'secondary', tone: 'destructive' },
  secondary_green: { type: 'secondary', tone: 'neutral' },
  secondary_grey: { type: 'secondary', tone: 'neutral' },
  secondary_orange: { type: 'secondary', tone: 'neutral' },
  secondary_black: { type: 'secondary', tone: 'neutral' },
  outline_primary: { type: 'secondary', tone: 'neutral' },
  outline_blue: { type: 'secondary', tone: 'neutral' },
  outline_red: { type: 'secondary', tone: 'destructive' },
  outline_green: { type: 'secondary', tone: 'neutral' },
  outline_grey: { type: 'secondary', tone: 'neutral' },
  outline_orange: { type: 'secondary', tone: 'neutral' },
  outline_black: { type: 'secondary', tone: 'neutral' },
  link_primary: { type: 'link', tone: 'neutral' },
  link_blue: { type: 'link', tone: 'neutral' },
  link_red: { type: 'link', tone: 'destructive' },
  link_green: { type: 'link', tone: 'neutral' },
  link_grey: { type: 'link', tone: 'neutral' },
  link_orange: { type: 'link', tone: 'neutral' },
  link_black: { type: 'link', tone: 'neutral' },
  text_primary: { type: 'tertiary', tone: 'neutral' },
  text_blue: { type: 'tertiary', tone: 'neutral' },
  text_red: { type: 'tertiary', tone: 'destructive' },
  text_green: { type: 'tertiary', tone: 'neutral' },
  text_grey: { type: 'tertiary', tone: 'neutral' },
  text_orange: { type: 'tertiary', tone: 'neutral' },
  text_black: { type: 'tertiary', tone: 'neutral' },
};

const propMapping = computed(() => {
  const map = {};
  const definition = COLOR_TYPE_MAPPING[`${props.type}_${props.color}`];

  map.type = definition.type;
  map.tone = definition.tone;
  // eslint-disable-next-line no-nested-ternary
  map.size = props.large ? 'large' : props.small ? 'small' : 'medium';
  map.disabled = props.disabled;
  map.iconLeft = props.iconLeft;
  map.iconRight = props.iconRight;
  map.iconSize = props.iconSize;
  map.loading = props.loading;

  if (props.success === true) {
    console.warn('Success state is not supported in ButtonV3');
  }

  return map;
});
</script>

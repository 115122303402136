<template>
  <OgModal
    data-cy="cmp-confirm-modal"
    :open="open"
    :class="rootClass"
    simple
    :with-header="withHeader"
    :title="title"
    :subtitle="subtitle"
    @close="$emit('reject')"
  >
    <slot />

    <template #actions>
      <ButtonV2
        v-if="!hideReject"
        type="outline"
        style="margin-left: 12px"
        :color="destructive ? 'red' : 'primary'"
        :small="smallButtons"
        @click="$emit(switchEmits ? 'accept' : 'reject')"
      >
        {{ rejectText }}
      </ButtonV2>
      <ButtonV2
        v-if="!hideConfirm"
        :disabled="confirmDisabled"
        :color="destructive ? 'red' : 'primary'"
        :small="smallButtons"
        @click="$emit(switchEmits ? 'reject' : 'accept')"
      >
        {{ confirmText }}
      </ButtonV2>
    </template>
  </OgModal>
</template>

<script>
import OgModal from './Modal.vue';
import ButtonV2 from './ButtonV2.vue';

export default {
  name: 'ConfirmModal',
  components: { ButtonV2, OgModal },
  props: {
    open: { type: Boolean, default: false },
    destructive: { type: Boolean, default: false },
    confirmText: { type: String, default: 'Accept' },
    rejectText: { type: String, default: 'Reject' },
    title: { type: String, default: null },
    subtitle: { type: String, default: null },
    withHeader: { type: Boolean, default: false },
    confirmDisabled: { type: Boolean, default: false },
    hideReject: { type: Boolean, default: false },
    hideConfirm: { type: Boolean, default: false },
    smallButtons: { type: Boolean, default: false },
    switchEmits: { type: Boolean, default: false },
  },
  emits: ['accept', 'reject'],
  computed: {
    rootClass: () => ({
      'confirm-modal': true,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.confirm-modal {
  @include font-body-lg-reg;
  color: $color-grey-900;
}

.confirm-modal__buttons {
  display: block;
  margin-top: 32px;
  text-align: right;
}
</style>

<template>
  <div
    data-cy="chat"
    class="chat"
    :class="{
      'chat--fullscreen': fullscreen,
      'chat--editor-extended': editorExtended,
    }"
  >
    <div
      class="chat__messages-wrapper"
      :class="{
        'chat__messages-wrapper--editor-expanded': editorExtended,
        'chat__messages-wrapper--min-height': !withList,
      }"
    >
      <div
        v-if="resizable && canChat"
        class="chat__fullscreen-toggle"
        @click="toggleFull"
      >
        <Icon
          :name="fullscreen ? 'collapse_bold' : 'expand_bold'"
          :size="15"
        />
      </div>
      <ChatConversationMessageArea
        ref="scrollArea"
        :logs="logs"
        :can-get-more-logs="canGetMoreLogs"
        :logs-loading="logsLoading"
        :conversation="conversation"
        :is-public="isPublic"
        :company="conversation.partner ? conversation.partner.name : 'Officeguru'"
        class="chat__messages"
        :class="{ 'conversation-handled-padding': conversationIsHandled }"
        @load-more="fetchLogs"
      />
      <ConversationHandledBanner
        v-if="conversationIsHandled"
        class="conversation-handled-banner"
        :conversation="conversation"
      />
    </div>

    <div
      class="chat__editor"
      :class="{ 'chat__editor--loading': editorLoading }"
    >
      <div
        v-if="resizable && canChat"
        class="chat__editor-toggle"
        :class="{ 'chat__editor-toggle--expanded': editorExtended }"
        @click="toggleEditorHeight"
      >
        <Icon
          class="editor-toggle-icon"
          name="arrow_bold"
          :size="20"
        />
      </div>
      <div
        v-if="shouldShowHandling"
        class="handled-wrapper"
        :class="{ 'handled-wrapper--no-margin': !resizable || !canChat }"
      >
        <ButtonV2
          type="secondary"
          small
          :icon-size="16"
          :icon-left="conversationHandledIcon"
          :loading="handlingLoading"
          @click="toggleHandled"
        >
          {{ conversationHandledText }}
        </ButtonV2>
      </div>
      <ChatConversationEditor
        v-model:editor-content="editorContent"
        :public-id="conversation.id"
        :is-public="isPublic"
        :chat-disabled="!canChat"
        :ai-enabled="aiEnabled"
        @editor-content-changed="editorContent = $event"
        @send-message="sendMessage"
        @loading="editorLoading = $event"
      />
    </div>
  </div>
</template>

<script>
import ChatConversationMessageArea from './chat-conversation-message-area.vue';
import ChatConversationEditor from './chat-conversation-editor.vue';
import Icon from '../Icon.vue';
import ButtonV2 from '../ButtonV2.vue';
import ConversationHandledBanner from './conversation-handled-banner.vue';

export default {
  name: 'ChatConversation',
  components: {
    ChatConversationEditor,
    ChatConversationMessageArea,
    Icon,
    ButtonV2,
    ConversationHandledBanner,
  },
  props: {
    conversation: { type: Object, required: true },
    canChat: { type: Boolean, required: true },
    resizable: { type: Boolean, required: false, default: true },
    isPublic: { type: Boolean, required: false, default: false },
    token: { type: [String, null], required: false, default: null },
    withList: { type: Boolean, default: false },
    useHandling: { type: Boolean },
    aiEnabled: { type: Boolean, default: false },
  },
  data() {
    return {
      editorContent: undefined,
      fullscreen: false,
      editorExtended: false,
      handlingLoading: false,
      editorLoading: false,
    };
  },
  computed: {
    shouldShowHandling() {
      return this.handlingFeatureEnabled && this.useHandling;
    },
    handlingFeatureEnabled() {
      if (this.isPublic) return false;

      return this.$store.getters['split/canAccess']('vendor-handle-conversations');
    },
    conversationIsHandled() {
      return this.shouldShowHandling && this.conversation?.handled?.user;
    },
    conversationHandledIcon() {
      return this.conversationIsHandled ? 'cross' : 'checkmark_bubble_light';
    },
    conversationHandledText() {
      return this.conversationIsHandled
        ? this.$t('shared.conversations.mark_unhandled')
        : this.$t('shared.conversations.mark_handled');
    },
    logs() {
      return this.$store.getters['conversationLogs/logs']({
        id: this.conversation.id,
      });
    },
    canGetMoreLogs() {
      return this.$store.getters['conversationLogs/canGetMoreLogs']({
        id: this.conversation.id,
      });
    },
    logsLoading() {
      return this.$store.getters['conversationLogs/isLoading']({
        id: this.conversation.id,
      });
    },
  },
  watch: {
    'conversation.id': {
      handler() {
        // we might have the case where the websocket pushes n logs into a conversation where
        // we haven't yet loaded the logs for - we can make sure we loaded the logs by checking
        // if we have logs meta because that's what we store when we did the request to
        // get the logs - if we already have them, we don't need to load them again
        // This does NOT affect the `load more`, just what happens when switching the conversation
        // in the conversation list.
        const logsLoaded = !!this.$store.getters['conversationLogs/hasLogsMeta']({
          id: this.conversation.id,
        });

        if (logsLoaded) return;

        this.fetchLogs();
      },
      immediate: true,
    },
  },
  created() {
    this.editorContent = this.$store.getters['conversations/editorDraft'](this.conversation.id);

    this.$nextTick(() => {
      // scroll the page to the top, since Quill will scroll the page down to show the editor
      window.scrollTo(0, 0);
    });
  },
  beforeUnmount() {
    this.fullscreen = false;
    this.togglePageScroll();

    this.$store.dispatch('conversations/saveEditorDraft', {
      conversationId: this.conversation.id,
      draft: this.editorContent,
    });
  },
  methods: {
    toggleHandled() {
      if (this.handlingLoading) return;
      if (this.conversationIsHandled) {
        this.unhandleConversation();
      } else {
        this.handleConversation();
      }
    },
    handleConversation() {
      this.handlingLoading = true;
      this.$store
        .dispatch('conversations/handle', {
          conversationId: this.conversation.id,
        })
        .finally(() => {
          this.handlingLoading = false;
        });
    },
    unhandleConversation() {
      this.handlingLoading = true;
      this.$store
        .dispatch('conversations/unhandle', {
          conversationId: this.conversation.id,
        })
        .finally(() => {
          this.handlingLoading = false;
        });
    },
    async fetchLogs() {
      await this.$store.dispatch('conversationLogs/getLogs', {
        id: this.conversation.id,
        isSupport: this.conversation.is_support,
        isPublic: this.isPublic,
        token: this.token,
      });
    },
    async sendMessage(payload) {
      await this.$store.dispatch('conversationLogs/addLog', {
        id: this.conversation.id,
        payload,
        isSupport: this.conversation.is_support,
        isPublic: this.isPublic,
        token: this.token,
      });
    },
    toggleFull() {
      document.querySelector('html').scrollTop = 0;
      this.fullscreen = !this.fullscreen;

      this.togglePageScroll();

      setTimeout(() => {
        if (!this.$refs.scrollArea) return;
        // eslint-disable-next-line no-unused-expressions
        this.$refs.scrollArea?.scrollToBottom?.();
      }, 100);
    },
    toggleEditorHeight() {
      this.editorExtended = !this.editorExtended;

      setTimeout(() => {
        if (!this.$refs.scrollArea) return;
        // eslint-disable-next-line no-unused-expressions
        this.$refs.scrollArea?.scrollToBottom?.();
      }, 100);
    },
    togglePageScroll() {
      document.querySelector('html').style.overflow = this.fullscreen ? 'hidden' : null;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../style/main";

@keyframes blurPulseVisible {

  0% {
    filter: blur(.08rem);
    opacity: .7;
  }

  50% {
    filter: blur(.08rem);
    opacity: .3;
  }

  100% {
    filter: blur(.08rem);
    opacity: .7;
  }
}

.chat {
  display: flex;
  flex-direction: column;
  z-index: 10;
  flex: 1 0 auto;
  width: 100%;
  background: white;
  border-radius: 12px;
  top: 250px;
  left: 32px;
  transition: max-width .4s ease-in-out,
    height .4s ease-in-out,
    max-height .4s ease-in-out,
    top .4s ease-in-out,
    left .4s ease-in-out;
  overflow: hidden;
  container-type: inline-size;

  &--fullscreen {
    position: absolute;
    top: 0;
    left: 0;
    max-width: 100% !important;
    height: 100vh !important;
    max-height: 100vh !important;
    min-height: 500px !important;
    z-index: 150;
  }

  :deep(div.ql-editor) {
    @include font-body-reg;
    color: $color-grey-900;
    transition: height .4s ease-in-out;
  }

  // placeholder
  :deep(div.ql-blank:before) {
    @include font-body-reg;
    color: $color-grey-500;
  }

  // message can get too close to the fullscreen toggle
  :deep(.bubble) {
    max-width: 87%;
  }

  .chat__messages {
    position: absolute;
  }

  &--editor-extended {

    :deep(div.ql-editor) {
      height: 35vh;
    }
  }

  &__messages-wrapper {
    flex: 1;
    position: relative;
    transition: min-height .4s ease-in-out;

    &--min-height {
      min-height: 400px;
    }

    &--editor-expanded {
      min-height: 0;
    }
  }

  &__messages {
    flex: 1;
    overflow-y: scroll;
    width: 100%;
    height: 100%;
  }

  &__editor {
    position: relative;
    border-top: 1px solid $color-grey-200;

    @include small-down {
      padding: 0;
    }

    &--loading {
      animation: blurPulseVisible 1.3s infinite;
      pointer-events: none;
    }
  }

  &__declined {
    color: $color-grey-300;
    font-size: 20px;
    text-align: center;
    min-height: 248px;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    justify-content: center;
  }

  &__fullscreen-toggle {
    position: absolute;
    background: white;
    z-index: 2;
    right: 24px;
    top: 24px;
    cursor: pointer;
    color: $color-grey-700;
    border: 1px solid $color-grey-400;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 9px 8px 6px 9px;
    transition: transform .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  &__editor-toggle {
    position: absolute;
    right: 17px;
    top: 17px;
    cursor: pointer;
    color: $color-grey-700;
    box-sizing: border-box;
    border-radius: 8px;
    padding: 9px 8px 5px 9px;
    transition: transform .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    .editor-toggle-icon {
      transform: rotate(0);
      transition: transform .4s ease-in-out;
    }

    &--expanded {

      .editor-toggle-icon {
        transform: rotate(180deg);
      }
    }
  }
}

.handled-wrapper {
  position: absolute;
  top: 17px;
  right: 64px;
  z-index: 2;
  display: flex;
  align-items: center;
  justify-content: center;

  &--no-margin {
    right: 24px;
  }
}

.conversation-handled-banner {
  position: absolute;
  bottom: 0;
  left: 0;
}

.conversation-handled-padding {
  padding-bottom: 50px !important;
}

@container (width < 450px) {

  .chat__editor {
    padding-top: 40px !important;
  }
}
</style>

<template>
  <button
    data-cy="cmp-icon-button"
    :class="rootClass"
    type="button"
    :disabled="disabled"
    @click="$emit('click', $event)"
  >
    <Icon
      :name="icon"
      :size="big ? 24 : 12"
    />
  </button>
</template>

<script>
import Icon from './Icon.vue';

export default {
  components: {
    Icon,
  },
  props: {
    /** The icon key */
    icon: { type: String, required: true },
    big: { type: Boolean },
    disabled: { type: Boolean, default: false },
  },
  emits: ['click'],
  computed: {
    rootClass() {
      return {
        iconbutton: true,
        'iconbutton--big': this.big,
        'iconbutton--disabled': this.disabled,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.iconbutton {
  cursor: pointer;
  border: 0;
  background: $color-grey-100;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: $color-grey-400;
  transition: background .15s linear, color .1s linear, opacity .1s linear;

  &:hover(.iconbutton--disabled) {
    background: $color-grey-900;
    color: white;
  }

  &:focus {
    outline: 0;
    box-shadow: 0 0px 0px 3px rgba($color-semantic-info-500, .3);
  }

  &--big {
    width: 48px;
    height: 48px;
  }

  &--disabled {
    opacity: .5;
    cursor: default;
    pointer-events: none;
  }
}
</style>

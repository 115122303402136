import { RouterView } from 'vue-router';

const routes = [
  {
    path: '/partners',
    name: 'Partners',
    component: RouterView,
    children: [
      {
        path: '',
        name: 'Partners List',
        component: () =>
          import(
            /* webpackChunkName: "partners" */ '@/views/partners/pages/partners-list.vue'
          ),
      },
      {
        path: 'create-external',
        name: 'Create External Partner',
        component: () =>
          import('@/views/partners/pages/vendors-single-external-edit.vue'),
      },
      {
        path: ':id/relationship',
        name: 'Partner Relationship',
        component: () =>
          import(
            /* webpackChunkName: "partners" */ '@/views/partners/pages/partners-single-relationship.vue'
          ),
      },
      {
        path: ':id/edit-external',
        name: 'Edit External Partner',
        component: () =>
          import('@/views/partners/pages/vendors-single-external-edit.vue'),
      },
    ],
  },
];

export default routes.map((route) => ({ ...route, meta: {} }));

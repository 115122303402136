<template>
  <div
    data-cy="cmp-conversation-preview"
    class="preview"
    :class="rootClass"
  >
    <ConversationsListPreviewIcon
      :service="service"
      :is-support="isSupport"
      :is-direct="isDirect"
      :show-fallback-service-icon="true"
      class="service-icon"
    />
    <div class="text">
      <div class="headline">
        <div class="headline-left">
          <span class="title">{{ title }}</span>
          <Icon
            v-if="isSupport"
            class="pin-icon"
            name="pin"
            :size="11"
          />
          <Icon
            v-if="isVerified"
            class="verified-icon"
            name="verified"
            :size="12"
          />
        </div>
        <InfoTag
          v-if="showUnhandled"
          style="margin-left: 4px;"
          color="yellow"
          x-small
        >
          {{ $t('shared.conversations.unhandled') }}
        </InfoTag>
      </div>
      <p
        v-if="taskName"
        class="subtitle"
      >
        {{ taskName }}
      </p>
      <p
        v-if="!hideExcerpt"
        class="excerpt"
        :class="{ 'excerpt--extended': !taskName }"
      >
        <span class="excerpt__inner">
          <em>{{ author }}: </em>{{ excerpt }}
        </span>
        <ConversationsListTimeStamp
          v-if="date"
          class="time"
          :date="date"
          :always-show-time="alwaysShowTime || null"
        />
      </p>
    </div>
    <div
      v-if="unread"
      class="unread-bubble"
    />
  </div>
</template>

<script>
import Icon from '../../../components/Icon.vue';
import ConversationsListPreviewIcon from './conversations-list-preview-icon.vue';
import ConversationsListTimeStamp from './conversations-list-time-stamp.vue';
import InfoTag from '../../../components/InfoTag.vue';

export default {
  name: 'ConversationsListPreview',
  components: {
    ConversationsListPreviewIcon,
    ConversationsListTimeStamp,
    Icon,
    InfoTag,
  },
  props: {
    showUnhandled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: true,
    },
    taskName: {
      type: String,
      default: '',
    },
    author: {
      type: String,
      required: true,
    },
    excerpt: {
      type: String,
      required: true,
    },
    date: {
      type: String,
      default: null,
    },
    alwaysShowTime: {
      type: Boolean,
      default: false,
    },
    unread: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    hideExcerpt: {
      type: Boolean,
      default: false,
    },
    service: {
      type: Object,
      required: true,
    },
    isSupport: {
      type: Boolean,
      default: false,
    },
    isVerified: {
      type: Boolean,
      default: false,
    },
    isDirect: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    rootClass() {
      return {
        'preview--selected': this.selected,
      };
    },
  },
};
</script>

<style scoped lang="scss">
@import "../../../style/main";

.preview {
  overflow: hidden;
  display: flex;
  padding: 16px 16px 12px 16px;
  transition: background-color .2s ease-in-out;
  min-height: 86px;
  align-items: center;

  &--selected {
    background: $color-grey-200;
  }
}

.text {
  flex: 1;
}

.headline {
  display: flex;
  align-items: center;
  margin: 0;
  justify-content: space-between;
  flex-direction: row;
  flex: 1;
  height: 20px;
  max-height: 20px;

  &-left {
    display: flex;
    align-items: center;
    margin: 0;
    flex: 1;
    overflow: hidden;
  }
}

.title {
  @include font-body-semi;

  @include overflow-ellipsis;
  display: block;
  color: $color-grey-900;
  line-height: 1.4;
}

.pin-icon {
  color: $color-grey-900;
  margin-left: 4px;
  min-width: 11px;
}

.verified-icon {
  color: $color-semantic-positive-400;
  margin-left: 4px;
  min-width: 12px;
}

.subtitle {
  @include font-body-reg;
  color: $color-grey-900;
  line-height: 1.4;
  min-height: 4px;
  height: 20px;
  max-height: 20px;
}

.excerpt {
  @include font-body-reg;
  color: $color-grey-500;
  line-height: 1.4;
  display: flex;
  align-items: center;
  height: 20px;
  max-height: 20px;

  &--extended {
    height: 40px;
    max-height: 40px;

    @supports (-webkit-line-clamp: 2) {

      .excerpt__inner {
        @include overflow-ellipsis-multiline($lines: 2);
        white-space: initial;
      }
    }
  }

  &__inner {
    @include overflow-ellipsis;
    flex-grow: 1;
  }

  em {
    font-style: normal;
  }
}

.time {
  @include font-caption-reg;
  color: $color-grey-500;
  line-height: 1.4;
  flex-wrap: nowrap;
  white-space: nowrap;
  margin-left: 8px;
}

.text {
  overflow: hidden;
}

.image {
  flex-grow: 0;
  flex-shrink: 0;
  margin-right: 12px;
}

.sender {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.unread-bubble {
  align-self: center;
  height: 10px;
  width: 10px;
  border-radius: 50%;
  background-color: $color-grey-900;
  margin-left: 12px;
}
</style>

<template>
  <div
    data-cy="view-conversations-message-block"
    class="wrapper"
  >
    <div class="date">
      <span class="date__inner">{{ dateFormatted }}</span>
    </div>
    <div class="message-block">
      <ChatConversationMessage
        v-for="message in block"
        :key="message.id"
        class="messagearea__message"
        :author="message.author"
        :message="message.message"
        :link-to="message.linkTo"
        :link-text="message.linkText"
        @rendered="$emit('message-rendered', { read: message.read })"
      >
        <template #attachments>
          <ImageList
            v-if="message.images.length"
            class="messagearea__message__images"
            :images="prepareImages(message.images)"
          />
          <div
            v-if="message.attachments.length"
            class="attachments"
          >
            <Attachment
              v-for="(att, index) in message.attachments"
              :key="index"
              class="attachments__item"
              :filename="decodeURI(att.split('/')[1])"
              @download="download(att)"
            />
          </div>
        </template>
      </ChatConversationMessage>
    </div>
  </div>
</template>

<script>
import Attachment from '../Attachment.vue';
import ImageList from '../ImageList.vue';
import ChatConversationMessage from './chat-conversation-message.vue';

function getStorageUrl() {
  return import.meta.env.VITE_ATTACHMENT_URL
    || 'https://storage.googleapis.com/officeguru/';
}

export default {
  name: 'ChatConversationMessageBlock',
  components: {
    Attachment,
    ImageList,
    ChatConversationMessage,
  },
  props: {
    block: {
      type: Array,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  emits: ['message-rendered'],
  computed: {
    locale() {
      return this.$store.getters['settings/locale'];
    },
    dateFormatted() {
      return this.$moment(this.date).format('dddd, DD. MMMM');
    },
  },
  methods: {
    download(attachment) {
      window.open(getStorageUrl() + attachment, '_blank');
    },
    prepareImages(images) {
      return images.map((image) => ({
        src: getStorageUrl() + image,
        thumbnail: getStorageUrl() + image.split('/').join('/tiny_'),
      }));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/main";

.date {
  @include font-body-reg;
  color: $color-grey-500;
  display: flex;
  flex-direction: row;

  &:before,
  &:after {
    content: "";
    flex: 1 1;
    border-bottom: 1px solid $color-grey-100;
    margin: auto;
  }

  &__inner {
    line-height: 0;
    border: 1px solid $color-grey-100;
    padding: 15px 12px 14px 12px;
    border-radius: 37px;
    text-transform: capitalize;
  }
}

.message-block {
  padding: 0 24px;
}

.messagearea {
  overflow-y: scroll;
  padding: 24px;

  &--empty {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    &__text {
      color: $color-grey-300;
      align-self: center;
      margin-top: 30px;
      font-size: 20px;
    }
  }
}

.messagearea__message__images {
  margin-top: 16px;
}

.attachments {
  margin: -8px 0 0 -8px;

  &__item {
    margin: 8px 0 0 8px;
  }
}

.messagearea__message__images + .attachments {
  margin-top: 0px;
}
</style>

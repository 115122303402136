<template>
  <div
    data-cy="cmp-conversation-message"
    class="wrapper"
  >
    <Avatar
      v-if="!showEmptyAvatar"
      class="avatar"
      :image="author?.media?.url"
      :text="initials"
    />
    <Avatar
      v-else
      class="avatar"
      :image="emptyAvatarImage"
    />
    <div class="content">
      <span class="author-name">{{ authorName }}</span>
      <span class="message-date">{{ dateFormatted }}</span>
      <span
        v-if="linkToEnabled && linkTo"
        class="internal-link"
      >
        (<router-link
          :to="linkTo"
          class="internal-link"
        >{{
          linkText
        }}</router-link>)
      </span>
      <!-- eslint-disable vue/no-v-html -->
      <div
        v-if="hasNonEmptyMessage"
        class="text"
        v-html="message.text"
      />
      <!-- eslint-enable vue/no-v-html -->
      <slot name="attachments" />
    </div>
  </div>
</template>

<script>
import { time } from '@officeguru/webapp-shared/src/helpers/date-format';
import { avatarInitials } from '@officeguru/webapp-shared/src/helpers/avatar-helper';
import Avatar from '../Avatar.vue';

export default {
  name: 'ChatConversationMessage',
  components: {
    Avatar,
  },
  props: {
    author: {
      type: Object,
      required: true,
    },
    message: {
      type: Object,
      required: true,
    },
    linkTo: {
      type: [String, Object],
      default: null,
    },
    linkText: {
      type: String,
      default: null,
    },
    showEmptyAvatar: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['rendered'],
  computed: {
    linkToEnabled() {
      /**
       * Currently the linkTo feature is broken as we get a wrong id from
       * the backend. We therefore decided to disable the linkTo
       * feature for now. @SEE https://officeguru.atlassian.net/browse/OG-2751
       */
      return false;
    },
    hasNonEmptyMessage() {
      if (!(this.message && this.message.text)) return false;

      return !!this.message.text.replace(/(<([^>]+)>)/gi, '').length;
    },
    initials() {
      const initials = this.author?.name || this.$t('shared.conversations.unknown_user');

      return avatarInitials(initials);
    },
    dateFormatted() {
      if (!this.message?.date) return '';
      return time(this.$moment(this.message.date));
    },
    authorName() {
      return this.author?.name ? this.author.name : this.$t('shared.conversations.unknown_user');
    },
    emptyAvatarImage() {
      // just a grey 1px image as the Avatar component doesn't support colors :)
      return 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjePLkw38ACSADuNtxJgkAAAAASUVORK5CYII=';
    },
  },
  created() {
    this.$emit('rendered');
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/main";

.wrapper {
  display: flex;
  margin-top: 16px;
  margin-bottom: 16px;
}

.avatar {
  flex-shrink: 0;

  :deep(.text) {
    @include font-caption-semi;
    color: white;
  }
}

.content {
  color: $color-grey-100;
  position: relative;
  display: inline-block;
  margin-left: 12px;
  margin-top: -5px;
}

.author-name {
  @include font-body-semi;
  color: $color-grey-900;
}

.message-date {
  @include font-caption-reg;
  color: $color-grey-500;
  margin-left: 4px;
}

.internal-link {
  @include font-body-reg;
  color: $color-grey-900;
}

.text {
  @include font-body-reg;
  color: $color-grey-900;
  white-space: break-spaces;

  :deep(a) {
    @include font-body-reg;
    color: $color-grey-900;
  }

  :deep(strong, b) {
    font-weight: 600;
  }

  :deep(em, i) {
    font-style: italic;
  }

  :deep(ul) {
    margin-left: 16px;
  }

  :deep(ul li) {
    list-style-type: initial;
  }

  :deep(ol) {
    margin-left: 16px;
  }

  :deep(ol li) {
    list-style-type: decimal;
  }
}
</style>

import { singleIntoArray } from '@/helpers/merge';
import Task from '@/store/models/Task';
import Rating from '@/store/models/Rating';
import axios from 'axios';
import captchaApi from '@/store/plugins/captcha-api';
import api from '../plugins/api';

export default {
  namespaced: true,
  state: {
    tasks: [],
    offers: [],
    contracts: [],
    ratings: [],
    listCancelToken: null,
    archiveModal: false,
  },
  getters: {
    list(state, _1, _2, getters) {
      return state.tasks.map((task) => new Task(task, getters));
    },
    single: (state, _1, _2, getters) => ({ id }) => {
      const task = state.tasks.find((t) => t.id === id);

      return task ? new Task(task, getters) : null;
    },
    offer(state) {
      return ({ offerid }) => state.offers.find((o) => o.id === offerid);
    },
    contract: (state, getters, _1, rootGetters) => ({ contractid }) => {
      const contract = state.contracts.find((o) => o.id === contractid);

      if (contract?.task) {
        return {
          contract: rootGetters['contracts/single']({ id: contract.id }),
          task: getters.single({ id: contract.task.id }),
        };
      }

      return contract;
    },
    ratings: (state) => () => {
      return state.ratings.map((rating) => new Rating(rating));
    },
  },
  mutations: {
    set_list(state, tasks) {
      state.tasks = tasks;
    },
    set_single(state, task) {
      const oldItem = state.tasks.find((t) => t.id === task.id);
      if (oldItem) {
        const index = state.tasks.findIndex((t) => t.id === task.id);
        state.tasks[index] = { ...oldItem, ...task };
      } else {
        state.tasks.push(task);
      }
    },
    set_offer(state, offer) {
      state.offers = singleIntoArray(state.offers, offer);
    },
    set_contract(state, contract) {
      state.contracts = singleIntoArray(state.contracts, contract);
    },
    set_ratings(state, ratings) {
      state.ratings = ratings;
    },
    setListCancelToken(state, token) {
      state.listCancelToken = token;
    },
    set_archiveModal(state, isOn) {
      state.archiveModal = isOn;
    },
  },
  actions: {
    get_list({ commit, getters }) {
      const source = axios.CancelToken.source();
      commit('setListCancelToken', source);
      return api
        .get('/tasks', {
          cancelToken: source.token,
          params: {
            with: ['service', 'location'],
          },
        })
        .then(({ data }) => {
          commit('set_list', data.data);
          commit('setListCancelToken', null);
          // eslint-disable-next-line dot-notation
          return getters['list'];
        });
    },
    get_single({ commit, getters, state }, { id }) {
      if (state.listCancelToken) {
        state.listCancelToken.cancel('Single was loaded');
        commit('setListCancelToken', null);
      }
      return api
        .get(`/tasks/${id}`, {
          params: {
            with: [
              'partners',
              'offers.partner',
              'offers.activity_logs',
              'service',
              'task_answers',
              'conversations.partner',
              'conversations.latest_log',
              'contact',
              'user',
              'location',
              'customer.contact',
              'reviews',
              'contracts.partner',
              'contracts.history.user',
              'contracts.module',
            ],
            recurring_contracts: true,
          },
        })
        .then(({ data }) => {
          commit('set_single', data.data);

          data.data.contracts.forEach((contract) => {
            commit('contracts/set_single', contract, { root: true });
          });

          // eslint-disable-next-line dot-notation
          return getters['single'](data.data);
        })
        .catch((err) => {
          return err;
        });
    },
    get_offer({ commit }, { taskid, offerid }) {
      return api
        .get(`/tasks/${taskid}/offers/${offerid}`, {
          params: {
            with: ['partner', 'activity_logs.user', 'recurring_offers'],
          },
        })
        .then(({ data }) => {
          commit('set_offer', data.data);
        });
    },
    get_contract({ commit }, { taskid, contractid }) {
      return api
        .get(`/tasks/${taskid}/contracts/${contractid}`, {
          params: {
            with: [
              'task.customer.contact',
              'task.user',
              'task.task_answers',
              'task.location',
              'task.reviews',
              'history.user',
            ],
            recurring_contracts: true,
          },
        })
        .then(({ data }) => {
          commit('set_contract', data.data);
          commit('set_single', data.data.task);
          commit('contracts/set_single', data.data, { root: true });
        });
    },
    get_ratings({ commit }, { taskId, rated, limit }) {
      const endpoint = taskId ? `tasks/${taskId}/ratings` : 'tasks/ratings';
      return api
        .get(endpoint, { params: { rated, limit } })
        .then(({ data }) => {
          commit('set_ratings', data.data);
        });
    },
    async create({ dispatch }, payload) {
      const { data } = await api.post('/tasks', payload);

      return dispatch('get_single', data.data);
    },
    async create_public(_, payload) {
      return captchaApi('create_task').post('/public/tasks', payload);
    },
    async get_lead_customer(_, { customerId }) {
      return captchaApi('get_lead_customer').get(
        `/public/customer/${customerId}`
      );
    },
    async update_lead_customer(
      _,
      {
        customerId,
        name,
        address,
        zip,
        city,
        terms,
        cvr,
        person,
        password,
        // eslint-disable-next-line camelcase
        password_confirmation,
        newsletter,
      }
    ) {
      return captchaApi('update_lead_customer').put(
        `/public/customer/${customerId}`,
        {
          customer: {
            name,
            address,
            zip,
            city,
            terms,
            cvr,
            person,
            password,
            password_confirmation,
            newsletter,
          },
        }
      );
    },
    send_review(_, { id, rating, feedback, hireAgain, date, time }) {
      return api.post(`/tasks/${id}/reviews`, {
        rating,
        description: feedback,
        hire_again: hireAgain,
        date,
        time,
      });
    },
    render(_, { task_id: taskId, id: offerId }) {
      return api.get(`/tasks/${taskId}/offers/${offerId}/render`);
    },
    render_contract(_, { task_id: taskId, id: contractId }) {
      return api.get(`/tasks/${taskId}/contracts/${contractId}/render`);
    },
    delete(_, { id }) {
      return api.delete(`/tasks/${id}`);
    },
    archive(_, { id, archiveComment, archiveReason }) {
      return api.post(`/tasks/${id}/archive`, {
        archived_comment: archiveComment,
        archived_reason: archiveReason,
      });
    },
  },
};

const routes = [
  {
    path: '/orders',
    children: [
      {
        path: '',
        name: 'Orders List',
        component: () =>
          import(
            /* webpackChunkName: "orders" */ '@/views/orders/pages/orders-list.vue'
          ),
      },
      {
        path: ':id',
        name: 'Order',
        component: () =>
          import(
            /* webpackChunkName: "orders" */ '@/views/orders/pages/orders-single.vue'
          ),
      },
    ],
  },
];

export default routes.map((route) => ({ ...route, meta: {} }));

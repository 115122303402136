<template>
  <div
    data-cy="cmp-upload-item"
    :class="rootClass"
  >
    <div
      v-if="backgroundImage"
      class="upload-item__image"
      :style="{backgroundImage: backgroundImage}"
    />
    <icon
      v-if="!backgroundImage"
      name="file-default"
      class="upload-item__placeholder"
    />
    <div class="upload-item__info">
      <div class="upload-item__message">
        <span
          class="upload-item__title"
        >{{ filename }}</span>
        <span
          v-if="error"
          class="upload-item__error__message"
        >{{ ' - ' + error }}</span>
      </div>
      <div
        class="upload-item__progress"
        :class="{'upload-item__progress--complete': progress === 100}"
      >
        <span
          class="upload-item__progress__bar"
          :style="{width: `${progress}%`}"
        />
      </div>
    </div>
    <button
      type="button"
      class="upload-item__delete"
      @click.prevent="$emit('delete')"
    >
      <icon name="times" />
    </button>
  </div>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'UploadItem',
  components: { Icon },
  props: {
    progress: {
      type: Number,
      required: true,
    },
    file: {
      type: Object,
      required: true,
    },
    error: { type: String, default: null },
  },
  emits: ['delete'],
  computed: {
    rootClass() {
      return {
        'upload-item': true,
        'upload-item--error': this.error,
      };
    },
    extension() {
      return this.filename.substr(this.filename.lastIndexOf('.') + 1).toLowerCase();
    },
    isImage() {
      return ['png', 'jpg', 'jpeg'].indexOf(this.extension) !== -1;
    },
    backgroundImage() {
      return (this.file.url && this.isImage) ? `url("${this.file.url}")` : null;
    },
    filename() {
      return this.file.title;
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/main";

.upload-item {
  display: flex;
  flex-direction: row;
  align-items: center;

  &--error {

    .upload-item__message {
      font-size: 12px;
      font-weight: 700;
      color: $color-semantic-negative-500;
    }

    .upload-item__title {
      color: $color-semantic-negative-500;
    }

    .upload-item__error__message {
      font-weight: 400;
    }
  }
}

.upload-item__image,
.upload-item__placeholder {
  width: 34px;
  height: 34px;
  flex-shrink: 0;
  align-self: flex-start;
}

.upload-item__image {
  background-size: cover;
}

.upload-item__placeholder {
  padding: 4px;
  background-color: $color-grey-200;
}

.upload-item__info {
  flex: 1;
  padding: 0 20px;
}

.upload-item__title {
  font-size: 12px;
  font-weight: 700;
  color: $color-grey-500;
}

.upload-item__progress {
  flex-grow: 1;
  display: flex;
  margin: 6px 0;
  height: 4px;
  border-radius: 2px;
  background-color: $color-grey-300;
  transition: height .15s linear, opacity .1s linear, margin .15s .1s linear,;

  &--complete {
    opacity: 0;
    height: 0;
    margin: 0;
  }

  &__bar {
    background-color: $color-grey-500;
    transition: width 300ms;
    border-radius: 2px;
  }
}

.upload-item__delete {
  background-color: transparent;
  cursor: pointer;
  color: $color-grey-500;
  height: 34px;
  width: 34px;
  padding: 0;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;

  &:hover {
    color: $color-grey-900;
  }
}
</style>

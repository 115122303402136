<template>
  <div class="skeleton-wrapper">
    <div
      v-for="n in [0,1,2,3,4,5,6,7,8,9,10,11,12,13,14,15]"
      :key="n"
      class="section-skeleton"
    >
      <div class="skeleton-icon" />
      <div class="skeleton-text-wrapper">
        <p class="skeleton-headline" />
        <p class="skeleton-subline" />
        <p class="skeleton-message" />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConversationsSkeleton',
};
</script>

<style lang="scss" scoped>
@import "../../../style/main";

.skeleton-wrapper {
  @include blur-pulse-loading;
}

.section-skeleton {
  display: flex;
  align-items: center;
  height: 86px;
  padding: 16px 16px 12px 16px;
  border-bottom: 1px solid $color-grey-200;
}

.skeleton-icon {
  width: 45px;
  height: 45px;
  background: $color-grey-200;
  margin-right: 16px;
  border-radius: 14px;
}

.skeleton-text-wrapper {
  flex-grow: 1;
}

.skeleton-headline {
  width: 70%;
  height: 5px;
  margin-bottom: 10px;
  background: $color-grey-400;
}

.skeleton-subline {
  width: 50%;
  height: 5px;
  margin-bottom: 10px;
  background: $color-grey-300;
}

.skeleton-message {
  width: 100%;
  height: 5px;
  background: $color-grey-200;
}
</style>

import calendar from './calendar';
import conversations from './conversations';
import settings from './settings';
import tasks from './tasks';
import partners from './partners';
import ratings from './ratings';
import orders from './orders';
import officehub from './officehub';

export default [
  {
    path: '/',
    redirect: { name: 'Home' },
  },
  {
    path: '/home',
    name: 'Home',
    component: () =>
      import(
        /* webpackChunkName: "home" */ '@/views/home-v2/pages/home-v2.vue'
      ),
  },
  // Used for E-Mails to send user directly to marketplace
  {
    path: '/marketplace-redirect',
    name: 'MarketplaceRedirect',
    component: () =>
      import(/* webpackChunkName: "home" */ '@/views/marketplace-redirect.vue'),
  },
  ...calendar,
  ...conversations,
  ...settings,
  ...tasks,
  ...partners,
  ...ratings,
  ...orders,
  ...officehub,
];

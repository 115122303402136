<template>
  <img
    data-cy="cmp-logo"
    src="../assets/og-logo.svg"
    :draggable="false"
  >
</template>

<script>
export default {
};
</script>

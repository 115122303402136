<template>
  <div class="navigation-container">
    <div
      class="mobile-navigation"
      data-cy="cmp-mobile-navigation"
      :class="{ 'mobile-navigation--hide': mobileNavigationExpanded }"
    >
      <div class="mobile-navigation-menu-box">
        <Icon
          name="navigation-menu"
          :size="24"
          class="mobile-navigation-menu-icon"
          @click="expandMobileNavigation"
        />
      </div>
      <Logo class="logo" />
      <!-- below element is used for even flex spacing -->
      <div class="mobile-navigation-menu-box" />
    </div>
    <div
      v-if="mobileNavigationExpanded"
      class="mobile-navigation-backdrop"
      @click="hideMobileNavigation"
    />
    <div
      data-cy="cmp-navigation"
      class="navigation"
      :class="{ 'navigation--mobile-expanded': mobileNavigationExpanded }"
    >
      <!-- TODO: Take care of this in the new design -->
      <div class="main">
        <GodModeBar
          v-if="me.super_user"
          :link="hubLink"
          :link-text="isCustomerApp ?
            $t('customer.back_to_hub') :
            $t('partner.navigation.back_to_hub')"
        >
          {{ isCustomerApp ? $t('customer.logged_in_as') : $t('partner.navigation.logged_in_as') }}
          <strong>{{ isCustomerApp ? me.customer.name : me.partner.name }}</strong>
        </GodModeBar>
        <router-link
          class="logo-link"
          to="/"
          data-cy="cmp-logo"
        >
          <Logo
            class="logo"
            style="padding-bottom: 32px;"
          />
        </router-link>

        <nav>
          <NavigationItem
            v-for="(link, index) in links"
            :key="index"
            :link="link"
            @hide-mobile-navigation="hideMobileNavigation"
          />
        </nav>
      </div>

      <div class="sub">
        <nav v-if="submenuLinks">
          <NavigationItem
            v-for="(link, index) in submenuLinks"
            :key="index"
            :link="link"
            @hide-mobile-navigation="hideMobileNavigation"
          />
        </nav>
        <router-link
          class="avatar-link"
          :to="avatar.to"
          active-class="avatar-link--active"
          data-cy="cmp-user-profile"
        >
          <div class="initials">
            <Avatar
              :image="me?.media?.url"
              :text="avatar.initials"
              style="width: 32px; height: 32px;"
            />
          </div>
          <div class="info">
            <span
              class="name"
              :title="avatar.label"
            >{{ avatar.label }}</span>
            <span
              class="company"
              :title="avatar.subLabel"
            >{{
              avatar.subLabel
            }}</span>
          </div>
        </router-link>
        <slot name="additional" />
      </div>
    </div>
  </div>
</template>

<script>
import { env } from '@officeguru/webapp-shared/src/helpers/environment';
import GodModeBar from './GodModeBar.vue';
import Logo from './Logo.vue';
import Icon from './Icon.vue';
import Avatar from './Avatar.vue';
import NavigationItem from './navigation-item.vue';

export default {
  components: {
    GodModeBar,
    Logo,
    Icon,
    Avatar,
    NavigationItem,
  },
  props: {
    links: {
      type: Array,
      required: true,
    },
    submenuLinks: {
      type: Array,
      default: null,
    },
    avatar: {
      type: Object,
      required: true,
    },
    application: {
      type: String,
      required: true,
      validator(value) {
        return ['customer', 'partner'].includes(value);
      },
    },
  },
  data() {
    return {
      hubLink: env.getEnvironmentUrl(),
      mobileNavigationExpanded: false,
    };
  },
  computed: {
    me() {
      return this.$store.getters['users/me'];
    },
    isCustomerApp() {
      return this.application === 'customer';
    },
    isPartnerApp() {
      return this.application === 'partner';
    },
  },
  watch: {
    $route() {
      this.hideMobileNavigation();
    },
  },
  methods: {
    expandMobileNavigation() {
      this.mobileNavigationExpanded = true;
    },
    hideMobileNavigation() {
      this.mobileNavigationExpanded = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.navigation {
  display: flex;
  flex-direction: column;
  width: 192px;
  background: $color-grey-50;
  border-right: 1px solid $color-grey-100;
  justify-content: space-between;
  height: 100vh;
  position: fixed;
  margin-left: 0;
  transition: margin .1s ease-in-out, height .1s ease-in-out, margin-left .2s;
  z-index: 100;
  overflow-y: auto;
  padding: 32px 16px 0 16px;

  // less height when customer god mode is active so we don't move the avatar / managed by
  // item out of the viewport
  .is-impersonating & {
    margin-top: 40px;
    height: calc(100vh - 40px);
  }

  .beamerAnnouncementBarTopActive & {
    height: calc(100vh - 55px);
  }
}

.expand,
.collapse {
  position: absolute;
  right: -3px;
  top: 50%;
  background: none;
  color: $color-grey-300;
  cursor: pointer;

  @media (min-width: 1280px) {
    display: none !important;
  }
}

.expand {
  display: none;
  transform: rotate(90deg);
}

.collapse {
  display: block;
  transform: rotate(-90deg);
}

.main {
  display: flex;
  flex-direction: column;
}

.sub {
  display: flex;
  flex-direction: column;
}

.logo-link {
  display: flex;
  width: 100%;
  justify-content: center;
}

.logo {
  width: 36px;
}

nav {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  justify-content: center;
  gap: 16px;
}

.avatar-link {
  display: flex;
  align-items: center;
  margin: 20px 0;
  padding: 0 6px 0 4px;
  border: 1px solid transparent;
  text-decoration: none;
  border-radius: 6px;

  &:not(&--active):hover {
    background: $color-grey-100;
  }

  &--active {
    background: $color-grey-200;
  }
}

.info {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.name {
  @include overflow-ellipsis;

  @include font-body-med;
  color: $color-grey-700;
  will-change: color;
  transition: color .2s ease-in-out;
}

.company {
  @include overflow-ellipsis;

  @include font-caption-reg;
  color: $color-grey-500;
  will-change: color;
  transition: color .2s ease-in-out;
}

.initials {
  margin-right: 12px;
}

.distanced {
  margin-top: 24px;
}

.mobile-navigation {
  display: none;
}

@include small-down {

  .navigation {
    margin-left: -192px;
  }

  .mobile-navigation {
    display: flex;
    background: $color-grey-100;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 50px;
    z-index: 50;
    justify-content: space-between;
    align-items: center;
    padding: 0 24px;
    transition: all .2s;

    &-menu-box {
      width: 50px;
    }

    &-menu-icon {
      cursor: pointer;
      color: $color-grey-700;
    }

    &--hide {
      margin-top: -50px !important;
    }

    .is-impersonating & {
      top: 40px;
    }
  }

  .navigation--mobile-expanded {
    margin-left: 0;
  }
}

.mobile-navigation-backdrop {
  background: rgba(0, 0, 0, .5);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 40;
  transition: all .2s;
}
</style>

import { RouterView } from 'vue-router';
import store from '@/store';

const routes = [
  {
    path: '/officehub',
    name: 'Officehub',
    component: RouterView,
    beforeEnter: redirectIfNotOfficehub,
    children: [
      {
        path: 'accounts',
        children: [
          {
            path: '',
            name: 'OfficehubAccountsList',
            component: () =>
              import('@/views/officehub/pages/accounts-list.vue'),
          },
          {
            path: 'create',
            name: 'OfficehubAccountsCreate',
            component: () =>
              import('@/views/officehub/pages/accounts-create.vue'),
          },
        ],
      },
      {
        path: 'lunch-overview',
        name: 'OfficehubLunchOverview',
        component: () => import('@/views/officehub/pages/lunch-overview.vue'),
      },
      {
        path: 'tasks-overview',
        name: 'OfficehubTasksOverview',
        component: () => import('@/views/officehub/pages/tasks-overview.vue'),
      },
    ],
  },
];

async function redirectIfNotOfficehub(_, _2, next) {
  // make sure non officehubs (e.g. sub-accounts) can not access the routes
  if (!store.getters['users/me']) {
    await store.dispatch('users/get_me');
  }

  return store.getters['users/me']?.customer?.officehub_enabled
    ? next()
    : next('/');
}

export default routes.map((route) => ({ ...route, meta: {} }));

<template>
  <div
    ref="scrollArea"
    data-cy="view-conversations-message-area"
    class="messagearea"
  >
    <LoadMoreButton
      :show="canGetMoreLogs"
      :disabled="logsLoading"
      :load-more-text="$t('shared.conversations.load_more_messages')"
      style="padding-bottom: 16px"
      @click="$emit('load-more')"
    />
    <ChatConversationMessageBlock
      v-for="(block, date) in messageBlocks"
      :key="date"
      :block="block"
      :date="date"
      @message-rendered="onMessageRendered"
    />
    <ChatConversationMessageAreaEmpty
      v-if="!logsLoading && logs.length === 0"
    />
  </div>
</template>

<script>
import _throttle from 'lodash.throttle';
import { isUnread } from '@officeguru/webapp-shared/src/helpers/conversation';
import ChatConversationMessageBlock from './chat-conversation-message-block.vue';
import ChatConversationMessageAreaEmpty from './chat-conversation-message-area-empty.vue';
import LoadMoreButton from '../load-more-button.vue';

const IMAGE_EXTS = /\.(jpg|jpeg|png)$/;

export default {
  name: 'ChatConversationMessageArea',
  components: {
    LoadMoreButton,
    ChatConversationMessageAreaEmpty,
    ChatConversationMessageBlock,
  },
  props: {
    logs: { type: Array, required: true },
    canGetMoreLogs: { type: Boolean, required: false, default: false },
    logsLoading: { type: Boolean, required: false, default: false },
    showRelatesTo: { type: Boolean, default: true },
    conversation: { type: Object, required: false, default: null },
    isPublic: { type: Boolean, required: false, default: false },
  },
  emits: ['load-more'],
  data() {
    return {
      oldScrollHeight: 0,
    };
  },
  computed: {
    hasUnread() {
      return isUnread(this.conversation, this.meId);
    },
    meId() {
      return this.$store.state.users.meId;
    },
    locale() {
      return this.$store.getters['settings/locale'];
    },
    myId() {
      return this.$store.state.users.meId;
    },
    token() {
      return this.$route.query.token || null;
    },
    messages() {
      const messages = this.logs.filter((log) => log.type === 'message');
      let lastMessageAuthor = null;
      let lastMessageDate = null;
      return messages.map((message) => {
        const content = typeof message.content === 'string' ? JSON.parse(message.content) : message.content;
        const files = content.files || [];
        let hideByline = false;
        const messageUser = message.user
          ? { ...message.user }
          : { id: '', type: null, name: this.$t('shared.conversations.deleted_user') };

        if (
          lastMessageDate
          && lastMessageAuthor === messageUser.id
          && new Date(message.created_at) - lastMessageDate < 1000 * 60 * 5
        ) {
          hideByline = true;
        }
        lastMessageDate = new Date(message.created_at);
        lastMessageAuthor = messageUser.id;

        let linkTo = null;
        let linkText = null;
        if (message.event_id && this.showRelatesTo) {
          linkTo = { name: 'Event', params: { id: message.event_id } };
          linkText = this.$t('shared.conversations.relates_to_date', { date: message.date });
        }
        return {
          ...message,
          attachments: files.filter((file) => !file.match(IMAGE_EXTS)),
          images: files.filter((file) => file.match(IMAGE_EXTS)),
          message: { text: content.message, date: message.created_at },
          author: messageUser,
          type: messageUser.id === this.myId ? 'mine' : 'theirs',
          fromOfficeguru: messageUser.type === 'backoffice',
          hideByline,
          linkTo,
          linkText,
        };
      });
    },
    messageBlocks() {
      return this.messages.reduce((blocks, message) => {
        const date = message.created_at.split('T')[0];
        // eslint-disable-next-line no-param-reassign
        blocks[date] = blocks[date] ? [...blocks[date], message] : [message];
        return blocks;
      }, {});
    },
  },
  watch: {
    messages: {
      async handler(newValue = [], oldValue = []) {
        if (
          newValue.length !== oldValue.length
          && newValue[0]?.id !== oldValue[0]?.id
        ) {
          // messages got prepended (load more)
          await this.scrollToOldScrollPosition();
        } else {
          // all other cases
          await this.goToBottom();
        }

        if (!this.$refs.scrollArea) return;

        this.oldScrollHeight = this.$refs.scrollArea.scrollHeight;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    window.addEventListener('focus', this.onFocus);
  },
  beforeUnmount() {
    window.removeEventListener('focus', this.onFocus);
  },
  methods: {
    onFocus() {
      if (this.hasUnread) {
        this.markConversationRead();
      }
    },
    onMessageRendered({ read }) {
      if (!document.hasFocus()) return;
      if (!this.conversation) return;
      if (read) return;

      this.markConversationRead();
    },
    // mark conversation read only once even when multiple unread messages
    markConversationRead: _throttle(
      // eslint-disable-next-line func-names
      function () {
        this.$store.dispatch('conversations/read', {
          id: this.conversation.id,
          isSupport: this.conversation.is_support,
          isPublic: this.isPublic,
          token: this.token,
        });
      },
      1000,
      { leading: false, trailing: true },
    ),
    async goToBottom() {
      await this.$nextTick();

      if (!this.$refs.scrollArea) return;

      this.$refs.scrollArea.scrollTop = 1e10;
    },
    async scrollToOldScrollPosition() {
      await this.$nextTick();

      if (!this.$refs.scrollArea) return;

      this.$refs.scrollArea.scrollTop = this.$refs.scrollArea.scrollHeight - this.oldScrollHeight;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/main";

.messagearea {
  position: relative;
  overflow-y: scroll;
  padding: 24px 0 0 0;

  &--empty {
    padding: 24px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;

    &__text {
      color: $color-grey-300;
      align-self: center;
      margin-top: 30px;
      font-size: 20px;
    }
  }
}
</style>

<template>
  <span
    class="verified-badge"
    data-cy="view-verified-badge"
  >
    <Icon
      class="verified-badge--icon"
      name="checkmark_circle_open"
      :size="12"
    />
    <span class="verified-badge--text">{{
      $t('customer.partners_list.verified')
    }}</span>
  </span>
</template>

<script>
import Icon from './Icon.vue';

export default {
  name: 'VerifiedBadge',
  components: {
    Icon,
  },
};
</script>

<style scoped lang="scss">
@import "../style/main";

.verified-badge {
  display: inline-flex;
  align-items: center;

  &--icon {
    margin-right: 4px;
    color: $color-semantic-positive-600;
  }

  &--text {
    @include font-caption-semi;
    color: $color-semantic-positive-600;
  }
}
</style>

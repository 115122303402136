<template>
  <div class="ai-wrapper">
    <Popover
      anchor-top
      @interface="popoverInterface = $event"
    >
      <template #trigger>
        <div
          :title="$t('shared.conversations.ai.improve_with_ai')"
          class="trigger"
          @click="logPopoverOpen"
        >
          <Icon
            name="temp_stars"
            :size="24"
          />
          <div class="divider" />
        </div>
      </template>
      <template #content>
        <div class="context">
          <ul class="action-list">
            <li
              class="revert"
              :class="{ 'can-revert': canRevert }"
              @click="onActionClick('revert')"
            >
              {{ $t("shared.conversations.ai.revert_last_action") }}
            </li>
            <li @click="onActionClick('spelling_and_grammar')">
              {{ $t("shared.conversations.ai.fix_spelling_and_grammar") }}
            </li>
            <li @click="onActionClick('longer')">
              {{ $t("shared.conversations.ai.make_text_longer") }}
            </li>
            <li @click="onActionClick('shorter')">
              {{ $t("shared.conversations.ai.make_text_shorter") }}
            </li>
            <li @click="onActionClick('friendlier')">
              {{ $t("shared.conversations.ai.make_text_sound_friendlier") }}
            </li>
            <li @click="onActionClick('professional')">
              {{ $t("shared.conversations.ai.make_text_sound_more_professional") }}
            </li>
          </ul>
        </div>
      </template>
    </Popover>
  </div>
</template>
<script>
import Popover from '../Popover.vue';
import Icon from '../Icon.vue';

export default {
  name: 'ChatConversationAi',
  components: { Popover, Icon },
  props: {
    getInnerHtml: { type: Function, required: true },
  },
  emits: ['loading', 'update'],
  data() {
    return {
      popoverInterface: {},
      tempText: '',
      textChangedByAi: false,
      aiLoading: false,
    };
  },
  computed: {
    canRevert() {
      return this.textChangedByAi !== false;
    },
  },
  methods: {
    onActionClick(type) {
      this.popoverInterface.hide();

      if (type === 'revert') {
        this.$posthog.capture('Reverted guru-ai action.');
        this.revertToOriginalText();
      } else {
        this.$posthog.capture('Used guru-ai action.', { type });
        this.improveWithAi(type);
      }
    },
    async improveWithAi(type) {
      this.aiLoading = true;
      this.tempText = this.getInnerHtml();
      this.$emit('loading', true);

      try {
        const response = await fetch(import.meta.env.VITE_GURU_AI_URL, {
          method: 'POST',
          body: JSON.stringify({
            message: this.getInnerHtml(),
            type,
          }),
          headers: {
            'OG-API-Token': `${this.$store.getters['auth/accessToken']}`,
          },
        });
        const data = await response.json();

        // eslint-disable-next-line vue/no-mutating-props
        this.$emit('update', data.data.message);
      } finally {
        this.textChangedByAi = true;
        this.aiLoading = false;
        this.$emit('loading', false);
      }
    },
    revertToOriginalText() {
      // eslint-disable-next-line vue/no-mutating-props
      this.$emit('update', this.tempText);
      this.textChangedByAi = false;
    },
    logPopoverOpen() {
      this.$posthog.capture('Opened guru-ai popover.');
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../style/main";

.ai-wrapper {
  position: absolute;
  top: 25px;
  left: 27px;

}

:deep(.popover-content-outer) {
  margin-bottom: 8px !important;
}

.trigger {
  color: $color-grey-900;
  cursor: pointer;
}

.divider {
  width: 2px;
  height: 18px;
  background-color: $color-grey-200;
  position: absolute;
  right: 0;
  top: 2px;
  border-radius: 2px;
  margin-right: -10px;
}

.action-list {
  white-space: nowrap;

  li {
    @include font-body-med;
    color: $color-grey-800;
    padding: 8px;
    border-radius: 8px;
    cursor: pointer;

    &:hover {
      background-color: $color-grey-100;
      color: $color-grey-900;
    }

    &.revert {
      color: $color-grey-300;
      cursor: not-allowed;
    }

    &.can-revert {
      color: $color-grey-800 !important;
      cursor: pointer !important;
    }
  }
}
</style>

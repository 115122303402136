<template>
  <div
    class="navigation-item-content"
    :class="{ 'navigation-item-content--active': isActive,
              'navigation-item-content--exact-active': isExactActive,
              'navigation-item-content--has-children': hasChildren }"
  >
    <Icon
      v-if="link.iconName"
      :name="link.iconName"
      class="menu-link-icon"
      :size="16"
      :style="{ marginBottom: link.marginBottom || '' }"
    /><span
      class="menu-link-text"
      :style="!link.iconName ? 'margin-left: 29px' : ''"
    >{{ link.label }}</span><span
      v-if="link.badge && link.badge.text && link.badge.show"
      class="badge"
      :class="`badge--${link.badge.color} ${link.badge.text?.length ? 'badge--multi-char' : '' }`"
    >{{ link.badge.text }}
    </span>
    <Icon
      v-if="hasChildren"
      name="arrow-down-bold"
      class="menu-link-icon open-icon"
      :size="12"
      style="margin-left: auto;"
    />
  </div>
</template>
<script>
import Icon from './Icon.vue';

export default {
  components: { Icon },
  props: {
    link: {
      type: Object,
      required: true,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isExactActive: {
      type: Boolean,
      default: false,
    },
    hasChildren: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style scoped lang="scss">
@import "../style/main";

[open] .open-icon {
  transform: rotate(180deg);
}

.navigation-item-content {
  display: flex;
  align-items: center;
  flex-grow: 1;
  justify-content: flex-start;
  border-radius: 6px;
  padding: 2px 6px;
  border: 1px solid transparent;
  transition: all .3s ease-in-out;

  &--active,
  &--active-exact {
    color: $color-grey-700;
    background: $color-grey-200;
  }

  &:not(&--active):not(&--active-exact):hover {
    background: $color-grey-100;
  }

  &--has-children {
    margin-bottom: 8px;
  }
}

.badge {
  border-radius: 9px;
  margin-left: 8px;
  min-height: 18px;
  min-width: 18px;
  padding: 0 4px;
  display: flex;
  align-items: center;
  justify-content: center;

  &--red {
    @include font-caption-semi;
    color: white;
    background: $color-coral-500;
  }

  &--green {
    @include font-caption-semi;
    outline: 2px solid $color-semantic-positive-400;
    color: $color-semantic-positive-400;
    padding: 0 4px 1px 4px;
  }

  &--gold {
    @include font-caption-med;
    outline: 1px solid $color-semantic-notice-200;
    color: $color-semantic-notice-200;
    padding: 1px 6px;
  }
}

.menu-link {
  @include font-body-reg;
  color: blue;
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  text-decoration: none;
  will-change: color;
  transition: font .2s ease-in-out, color .2s ease-in-out;
  cursor: pointer;
  user-select: none;

  &--active {
    @include font-body-semi;
    color: white;
  }

  &:hover {
    color: white;
  }
}

.menu-link-icon {
  margin-right: 12px;
  color: $color-grey-500;
}

.open-icon {
  transition: all .2s;
  color: $color-grey-500;
}
</style>

<template>
  <div
    data-cy="cmp-attachment"
    :class="rootClass"
  >
    <Icon
      :name="icon"
      class="fileicon"
    />
    <span class="filename">{{ filename }}</span>
    <IconButton
      icon="download"
      @click="$emit('download')"
    />
  </div>
</template>

<script>
import Icon from './Icon.vue';
import IconButton from './IconButton.vue';

const ICON_MAP = {
  doc: 'document',
  docx: 'document',
  xls: 'spreadsheet',
  xlsx: 'spreadsheet',
  pdf: 'pdf',
  ppt: 'presentation',
  key: 'presentation',
  mp3: 'audio',
  mp4: 'video',
  mov: 'video',
  zip: 'zip',
};

const ICON_DEFAULT = 'file-default';

export default {
  components: {
    Icon,
    IconButton,
  },
  props: {
    filename: { type: String, required: true },
    size: { type: Number, default: null },
  },
  emits: ['download'],
  computed: {
    icon() {
      if (typeof this.filename !== 'string') return ICON_DEFAULT;

      const matches = this.filename.match(/\.([^.]+)$/);

      if (Array.isArray(matches) && matches[1]) {
        const extension = matches[1].toLowerCase();
        const icon = ICON_MAP[extension];
        return icon ? `file-${icon}` : ICON_DEFAULT;
      }

      return ICON_DEFAULT;
    },
    rootClass() {
      return {
        attachment: true,
        [`attachment--${this.icon}`]: true,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../style/main";

.attachment {
  color: $color-grey-300;
  display: inline-flex;
  align-items: center;
  padding: 8px;
  background: #fff;
  border-radius: 4px;

  &--file-document,
  &--file-audio {
    color: $color-semantic-info-500;
  }

  &--file-video,
  &--file-pdf {
    color: $color-semantic-negative-500;
  }

  &--file-zip,
  &--file-presentation {
    color: $color-semantic-notice-400;
  }

  &--file-spreadsheet {
    color: $color-semantic-positive-300;
  }
}

.filename {
  color: rgba($color-grey-900, .8);
  font-size: 12px;
  font-weight: 500;
  margin: 0 16px 0 8px;
}

.fileicon {
  width: 24px !important;
  height: 24px !important;
}
</style>
